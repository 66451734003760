import React from "react";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import LpFormShort from "../../components/ConversionPath/LpFormShort";
import Image from "../../components/image";
import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
import { demoTestimonials } from "../../data/restaurant-data";
import TrustedNumnbers from "../../components/TrustedNumbers/TrustedNumbers";
import { terminalTrustedNumbers } from "../../data/terminal-landing-data";
import PartnerLogos from "../../components/PartnerLogos/PartnerLogos";
import Badges from "../../components/ConversionPath/Badges";

import ogImage from "../../images/global_assets/og-image.png";
import data from "../../data/forms/lead-form";
import { extractDefaultValues } from "../../utils/form-utils";
import { lineOfBusiness } from "../../constants";
import LeadForm from "../../components/Forms/LeadForm";

export default function DemoPageLp() {
  return (
    <LpLayout phoneNumber="+19378300828" phoneText="(937) 830-0828">
      <SEO
        title="Learn more | SpotOn"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            <LeadForm
              formName="Lead Form"
              displayForm
              formData={data}
              initialValues={extractDefaultValues(data)}
              header="Get your free demo"
              subHeader="Adapt and grow your restaurant with an end-to-end solution built by
            restaurateurs."
              onValuesChange={() => true}
              useFormValueAsDataLayerEventName
              formValueForDataLayerEventName="industry"
              dataLayerEventNameAppendix="-lead"
              withLineOfBusinessOption={false}
              lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
              thankYouPath="/lp/thank-you"
              leadType=""
            />
          </div>
        </div>
        <Image imageName="demo_image.png" className="demo__heading-img" />
        <section style={{ marginTop: 120 }}>
          <Badges />
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
      <section style={{ marginBottom: 40 }}>
        <PartnerLogos />
      </section>
    </LpLayout>
  );
}
